<template>
  <div>
    <div class="d-flex justify-content-end mt-3">
      <template v-if="this.data.projects.length > 0">
        <input
          :id="'fileImportExcel'"
          @input="onFileChange($event)"
          type="file"
          hidden
        />

        <a
          class="btn btn-light-warning font-weight-bolder font-size-sm mr-2"
          @click="importData"
          :disabled="loadingDesign"
          ref="kt_import_data"
        >
          <i class="fa fa-file-excel"></i>
          Import
        </a>

        <vue-excel-xlsx
          :data="exportList"
          :columns="columns"
          :file-name="fileNameExcel"
          :sheetname="'sheet1'"
        >
          <button
            class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
            @click="showDownloadNotification"
            :disabled="exportList.length === 0"
          >
            <i class="fa fa-file-excel"></i>
            Export
          </button>
        </vue-excel-xlsx>
      </template>

      <b-button
        class="btn btn-success font-weight-bolder font-size-sm mr-2"
        @click="onSave()"
        ref="kt_simpan_planing_date"
        v-if="this.data.dld.length > 0"
      >
        <i class="flaticon2-check-mark" />Save
      </b-button>

      <button
        class="btn btn-info font-weight-bolder font-size-sm mr-2"
        @click="onLoadDesign"
        ref="kt_load_design"
      >
        <i class="fa fa-sync" />Load Design
      </button>
    </div>

    <div class="table-responsive mt-3">
      <complete-table :loading="loadingDesign" :data="filteredDesign">
        <template #header>
          <tr class="text-left">
            <th style="min-width: 50px" class="pl-7">
              <span class="text-dark-75">No.</span>
            </th>
            <th></th>
            <th style="min-width: 200px" class="pl-7">
              <span class="text-dark-75">Numbering</span>
            </th>
            <th style="min-width: 100px">Title</th>
            <th style="min-width: 80px">Planing Date</th>
            <th style="min-width: 80px">Return Item</th>
            <th style="min-width: 80px">Outstanding</th>
            <th style="min-width: 200px">Action</th>
          </tr>
        </template>

        <template #defaultBody="{ item, i, number }">
          <tr
            v-bind:key="i"
            :class="{ opened: opened.includes(item.numbering) }"
          >
            <td class="pl-0">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ number }}
              </span>
            </td>
            <td>
              <button
                class="btn btn-default btn-xs"
                @click="toggle(item.numbering)"
              >
                <span class="flaticon-eye"></span>
              </button>
            </td>
            <td class="pl-0">
              <span
                class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.numbering }}
              </span>
            </td>

            <td>
              <span
                class="text-dark-75 font-weight-bolder d-block font-size-lg"
              >
                {{ item.title }}
              </span>
            </td>

            <td>
              <template v-if="currentIndex !== i">
                <span class="text-muted font-weight-bold d-block">
                  {{ item.planingDate }}
                </span>
              </template>

              <template v-if="currentIndex === i">
                <b-input-group>
                  <input
                    class="form-control"
                    type="text"
                    v-model="item.planingDate"
                    id="dtPlaningDate"
                    placeholder="YYYY-MM-DD"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      button-only
                      right
                      reset-button
                      reset-value=""
                      v-model="item.planingDate"
                      :no-flip="true"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </template>
            </td>

            <td>
              <span class="text-muted font-weight-bold d-block">
                {{ item.returnItem }}
              </span>
            </td>

            <td>
              <span class="text-muted font-weight-bold d-block">
                {{ item.outstanding.name }}
              </span>
            </td>

            <td class="pr-0">
              <template v-if="currentIndex !== i">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="edit(i, item.id)"
                >
                  <i class="flaticon-edit"></i>
                </a>
              </template>

              <template v-if="currentIndex === i">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="update()"
                >
                  <i class="flaticon2-check-mark"></i>
                </a>

                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-3"
                  @click="cancelEdit()"
                >
                  <i class="flaticon2-cancel-music"></i>
                </a>
              </template>
            </td>
          </tr>
          <tr v-bind:key="1000 + i" v-if="opened.includes(item.numbering)">
            <td colspan="12">
              <template>
                <div>
                  <div class="card p-5 gutter-b">
                    <div class="card-body p-0">
                      <div class="row">
                        <div class="col-md-3">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Type : </span
                          ><span class="text-dark-75">{{ item.type }}</span>
                        </div>
                        <div class="col-md-3">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Station : </span
                          ><span class="text-dark-75">{{ item.station }}</span>
                        </div>
                        <div class="col-md-3">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Approved : </span
                          ><span class="text-dark-75">{{ item.approved }}</span>
                        </div>
                        <div class="col-md-3">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Planning Date : </span
                          ><span class="text-dark-75">{{
                            item.planingDate
                          }}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Category : </span
                          ><span class="text-dark-75">{{ item.category }}</span>
                        </div>
                        <div class="col-md-3">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Designer PIC : </span
                          ><span class="text-dark-75">
                            {{ item.designerPic.code }}</span
                          >
                        </div>
                        <div class="col-md-3">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Drafter PIC : </span
                          ><span class="text-dark-75">
                            {{ item.drafterPic.code }}</span
                          >
                        </div>
                        <div class="col-md-3">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Checker PIC : </span
                          ><span class="text-dark-75">
                            {{ item.checkerPic.code }}</span
                          >
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-3">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Scope : </span
                          ><span class="text-dark-75">{{ item.scope }}</span>
                        </div>
                        <div class="col-md-3">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Designer Hour : </span
                          ><span class="text-dark-75">
                            {{ item.designerHour }}</span
                          >
                        </div>
                        <div class="col-md-3">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Drafter Hour : </span
                          ><span class="text-dark-75">{{
                            item.drafterHour
                          }}</span>
                        </div>
                        <div class="col-md-3">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >Checker Hour : </span
                          ><span class="text-dark-75">{{
                            item.checkerHour
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </td>
          </tr>
        </template>
      </complete-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_PLANING_DATE_DOC,
  CLEAN_STATE,
  POST_PLANING_DATE,
} from "@/core/services/store/planing.date.module.js";
import { GET_OUTSTANDING } from "@/core/services/store/outstanding.module";
import { GET_PIC } from "@/core/services/store/pic.module";
import CompleteTable from "../../../content/widgets/advance-table/CompleteTable";
import readXlsxFile from "read-excel-file";
import moment from "moment";
import { showToast } from "@/core/helper/toast.helper";

export default {
  name: "Document",
  components: {
    CompleteTable,
  },
  props: {
    project: {
      required: true,
    },
    dataFilter: {
      required: true,
    },
  },
  data() {
    return {
      opened: [],
      currentIndex: null,
      currentID: null,
      oldData: {},
      data: {
        dld: [],
        projects: [],
      },
      pagination: {
        page: 1,
        total: 0,
        rowsPerPage: 5,
      },
      search: "",
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Numbering",
          field: "numbering",
        },
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Planing Date (Changeable)",
          field: "planingDate",
        },
        {
          label: "Return Item",
          field: "returnItem",
        },
        {
          label: "Outstanding",
          field: "outstanding",
        },
      ],
    };
  },
  methods: {
    showDownloadNotification() {
      showToast("Info", "Downloading !", "info");
    },

    onChangeDate(object) {
      var project = this.data.projects.find((c) => c.id === this.currentID);
      if (project !== undefined)
        project.planingDate = moment(object.target.value).format("DD-MM-YYYY");
    },

    formatterDate(value) {
      var date = new Date(value);
      var result = moment(date).format("DD-MM-YYYY");
      return result;
    },

    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;

      readXlsxFile(xlsxfile).then((rows) => {
        this.onImportData(rows);
      });
    },

    onImportData(listData) {
      let dataSubmit = [];

      for (let i = 1; i < listData.length; i++) {
        dataSubmit.push({
          id: listData[i][0],
          planning_date: listData[i][3]
            ? moment(listData[i][3]).format("DD-MM-YYYY")
            : null,
        });
      }

      const submitButton = this.$refs["kt_import_data"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      setTimeout(() => {
        this.$store
          .dispatch(POST_PLANING_DATE, {
            project: this.project,
            dld: dataSubmit,
          })
          .then(() => {
            setTimeout(() => {
              showToast("Success", "import data success");

              this.onLoadDesign();
            }, 2000);
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    doReset() {
      this.onLoadDesign();
    },
    importData() {
      document.getElementById("fileImportExcel").click();
    },

    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },

    edit(index, id) {
      var project = this.data.projects.find((c) => c.id === id);
      this.currentIndex = index;
      this.currentID = id;
      this.oldData = project;
    },

    remove(id) {
      this.data.projects = this.data.projects.find((c) => c.id !== id);
    },

    cancelEdit() {
      var indexData = this.data.projects.findIndex(
        (item) => item.id === this.currentID
      );
      this.data.projects[indexData] = this.oldData;
      this.currentIndex = null;
    },

    update() {
      var indexData = this.data.projects.findIndex(
        (item) => item.id === this.currentID
      );

      var project = this.data.projects[indexData];
      //project.planingDate = moment(project.planingDate).format("DD-MM-yyyy
      if (project !== null) {
        if (this.data.dld.filter((c) => c.id === project.id).length > 0) {
          this.data.dld = this.data.dld.filter((c) => c.id !== project.id);
        }

        this.data.dld.push({
          id: project.id,
          planning_date: project.planingDate,
        });
      }

      this.currentIndex = null;
    },

    loadDesign(projectID) {
      const submitButton = this.$refs["kt_load_design"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.data.dld = [];
      setTimeout(() => {
        this.$store
          .dispatch(GET_PLANING_DATE_DOC, {
            project_id: projectID,
          })
          .then(() => this.mappingDocumentObject(this.currentPlaningDateDoc));

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 1000);
    },

    cleanDocument() {
      this.$store.dispatch(CLEAN_STATE, "DOC");
      this.data.projects = [];
    },
    getPicName(code) {
      var result = this.currentPicOptions.find((x) => x.value === code);

      if (result !== undefined) return result.text;

      return "";
    },
    getOutstandingName(code) {
      var result = this.currentOutstandingOptions.find((x) => x.value === code);
      if (result !== undefined) return result.text;

      return "";
    },
    mappingDocumentObject(listDocument) {
      this.data.projects =
        listDocument.map((doc) => {
          return {
            id: doc.id,
            numbering: doc.numbering,
            title: doc.title,
            type: doc.type ? doc.type.name : null,
            category: doc.category ? doc.category.name : null,
            scope: doc.scope ? doc.scope.name : null,
            station: doc.station ? doc.station.name : null,
            designerPic: {
              code: doc.designer_pic ? doc.designer_pic.code : null,
              name: doc.designer_pic ? doc.designer_pic.name : null,
            },
            designerHour: doc.designer_hour,
            drafterPic: {
              code: doc.drafter_pic ? doc.drafter_pic.code : null,
              name: doc.drafter_pic ? doc.drafter_pic.name : null,
            },
            drafterHour: doc.drafter_hour,
            checkerPic: {
              code: doc.checker_pic ? doc.checker_pic.code : null,
              name: doc.checker_pic ? doc.checker_pic.name : null,
            },
            checkerHour: doc.checker_hour,
            approved: doc.approved,
            planingDate: doc.planning_date
              ? moment(doc.planning_date).format("DD-MM-YYYY")
              : null,
            status: doc.status,
            revision: doc.revision_version,
            version: doc.version,
            dateReceive: doc.date_receive,
            delay: doc.delay,
            storageRuangDoc: doc.storageRuangDoc,
            server: doc.server,
            asbuilt: doc.monitoring_asbuilt_status,
            returnItem: doc.return_item,
            outstanding: {
              code: doc.outstanding ? doc.outstanding.code : null,
              name: doc.outstanding ? doc.outstanding.name : null,
            },
          };
        }) ?? [];
    },

    onLoadDesign() {
      if (this.project.code === null) {
        this.$bvToast.toast("Please Choose Project first.", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      } else {
        this.loadDesign(this.project.code);
      }
    },

    onSave() {
      // set spinner to submit button
      if (this.data.dld.length > 0) {
        const submitButton = this.$refs["kt_simpan_planing_date"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        setTimeout(() => {
          this.$store
            .dispatch(POST_PLANING_DATE, {
              project: this.project,
              dld: this.data.dld,
            })
            .then(() => {
              setTimeout(() => {
                this.onLoadDesign();
              }, 2000);
            });

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }, 2000);
      } else {
        this.$bvToast.toast("Nothing Change!.", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      "getterPlaningDateDoc",
      "loadingDesign",
      "currentPics",
      "currentOutstanding",
    ]),
    fileNameExcel() {
      return (
        this.project.code +
        "-" +
        this.project.nickname +
        "-" +
        "Document" +
        " Planning Date"
      );
    },
    currentPlaningDateDoc() {
      return this.getterPlaningDateDoc;
    },

    currentIdProject() {
      return this.project.code;
    },

    currentPicOptions() {
      return this.currentPics.map((pic) => {
        return {
          value: pic.code,
          text: pic.code + "-" + pic.name,
        };
      });
    },

    currentOutstandingOptions() {
      return this.currentOutstanding.map((ot) => {
        return {
          value: ot.code,
          text: ot.code + "-" + ot.name,
        };
      });
    },

    filterItems() {
      return this.data.projects.filter((items) => {
        return items.numbering.match(this.search);
      });
    },

    filteredDesign() {
      const station = this.dataFilter.station;
      const scope = this.dataFilter.scope;
      const title = this.dataFilter.title;
      return this.data.projects?.filter(
        (design) =>
          (station ? design.station === station : true) &&
          (scope ? design.scope === scope : true) &&
          (title
            ? design.title?.toLowerCase()?.includes(title?.toLowerCase())
            : true)
      );
    },

    exportList() {
      return this.filteredDesign?.map((doc) => {
        return {
          id: doc.id,
          numbering: doc.numbering,
          title: doc.title,
          planingDate: doc.planingDate,
          returnItem: doc.returnItem,
          outstanding: doc.outstanding.code,
        };
      });
    },
  },
  async created() {
    await this.$store.dispatch(GET_PIC);
    await this.$store.dispatch(GET_OUTSTANDING);
  },
  watch: {
    currentIdProject(value) {
      if (value === null) {
        this.cleanDocument();
      }
    },
    reloadFilter(value) {
      if (value === null) {
        this.filteredDesign();
      }
    },
  },
};
</script>
